import {useEffect, useState} from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ChartComponent = (props) => {
    const [chartConfig, setChartConfig] = useState({});
    const dimension = props.dimension;
    const dimensionData = props.dimensionData;
    const metricData = props.metricData;

    // only update chart when data is changed
    useEffect(() => {
        const options = {
            title: {
                //text: "Ad Performance Chart",
                text: null,
                align: "center"
            },
            xAxis: {
                categories: dimensionData ? dimensionData : [],
                title: {
                    text: null
                }
            },
            yAxis: [{
                type: 'logarithmic',
                min: 1,
                title: {
                    text: "Axis for bars"
                }
            }, {
                title: {
                    text: 'Axis for lines',
                },
                opposite: true,
            }],
            legend: {
                enabled: true
            },
            series: metricData ? metricData : [],
            plotOptions: {
                series: {
                    lineWidth: 2
                }
            },
        };
        setChartConfig(options);
    }, [dimension, dimensionData, metricData]);


    return (
        <div className="my-chart">
            <HighchartsReact highcharts={Highcharts} options={chartConfig}/>
        </div>
    );
};

export default ChartComponent;
