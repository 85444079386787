import {useEffect, useState} from "react";
import callQueryAPI from "./callQueryAPI";
import ChartComponent from "./createChart";
import {displayedName} from "./constants";



const RunReport = (props) => {
    // store values to plot chart
    const [chartDimensionData, setChartDimensionData] = useState([]);
    const [chartMetricData, setChartMetricsData] = useState([]);

    const [waitingQueryAPIResponse, setWaitingQueryAPIResponse] = useState(false);
    const [displayWarning, setDisplayWarning] = useState("");
    const [showChart, setShowChart] = useState(false);

    const runReport = () => {
        setShowChart(false);
        if (!Object.keys(props.advertiserDict).length) {
            setDisplayWarning("Please enter 'Advertiser Name/ID' before running report!")
        }
        else if (
            (props.advertiserDict.hasOwnProperty("advertiser_id") && props.advertiserDict.advertiser_id.trim() == "")
            ||
            (props.advertiserDict.hasOwnProperty("advertiser_name") && props.advertiserDict.advertiser_name.trim() == "")
            ) {
            setDisplayWarning("'Advertiser Name/ID' cannot be empty!")
        }
        else if (!props.metricList.length) {
            setDisplayWarning("Please select 'Metrics' before running report!")
        }
        else if (!props.dimension) {
            setDisplayWarning("Please select 'Group By' before running report!")
        }
        else {
            setDisplayWarning("");
            setWaitingQueryAPIResponse(true);
            const combinedFilterDict = Object.assign({}, props.filterDict, props.advertiserDict);
            callQueryAPI(props.dimension, props.metricList, combinedFilterDict)
            .then(playload => {
                setWaitingQueryAPIResponse(false);
                setShowChart(true);
                // update chart with api response
                setChartDimensionData(playload.chartDimensionData);
                setChartMetricsData(playload.chartMetricData);
            })            
        }
    };


    return (
        <div>
            <p></p>              
            <div className="hint-container">
                <button onClick={runReport}>Run Report</button>
                <span className="hint">(Hint)</span>
                <div className="hint-info">
                    <li> Numbers are approximate as they are based on sampled data and should not be used for billing. </li>
                    <li> The chart shows top 10 groups with most "{displayedName['impressions']}".</li>
                    <li> "{displayedName['win_rate']}" and "{displayedName['click_through_rate']}" are in percentage, e.g. 0.03 means 0.03%.</li>
                    <li> "{displayedName['ave_bidder_slot_bid_amount']}" and "{displayedName['ave_impression_bid_amount']}" are in US dollars.</li>
                </div>
            </div>
            {displayWarning && (<p>[warning] {displayWarning} </p>)}
            {waitingQueryAPIResponse && (<p>[info] Retrieving data from server, please wait...</p>)}
            {showChart && (<ChartComponent 
                dimensionData={chartDimensionData} 
                metricData={chartMetricData}
            />)}
        </div>
    );
};

export default RunReport;