import axios from 'axios';
import {Auth} from 'aws-amplify';

async function callAutocompleteAPI(matchKey, matchValuePrefix, scope) {
    try {
        const session = await Auth.currentSession();
        const IdToken = session.getIdToken().getJwtToken();

        //const endpoint = "https://mskkyqbdub.execute-api.us-east-1.amazonaws.com/dev";
        const endpoint = "https://r1rbmfiqz5.execute-api.us-east-1.amazonaws.com/dev";
        const headers = {
            "Content-Type": "application/json",
            "Authorization": IdToken
            };
        const payload = {
            "match_field": matchKey,
            "match_value_prefix": matchValuePrefix,
            "scope": scope,
        };
        //console.log("Autocomplete API request payload:", payload);

        const response = await axios.post(
            `${endpoint}/autocomplete`, 
            payload, 
            {
                headers: headers
            }
        );
        //console.log("Autocomplete API response:", response);
        //console.log("Autocomplete API response playload:", response.data);
        return response.data;
    } 
    catch (error) {
        console.error('Autocomplete API Error fetching data:', error);
        return [];
    }
};


export default callAutocompleteAPI;
