import axios from 'axios';
import {Auth} from 'aws-amplify';

async function callQueryAPI(dimension, metricList, filterDict) {
    try {
        const session = await Auth.currentSession();
        const IdToken = session.getIdToken().getJwtToken();

        //const endpoint = "https://mskkyqbdub.execute-api.us-east-1.amazonaws.com/dev";
        const endpoint = "https://r1rbmfiqz5.execute-api.us-east-1.amazonaws.com/dev";
        const headers = {
            "Content-Type": "application/json",
            "Authorization": IdToken
            };
        const payload = {
            "dimension": dimension,
            "metric_list": metricList,
            "filter_dict": filterDict
        };
        console.log("Query API request payload:", payload);

        const response = await axios.post(
            `${endpoint}/query`, 
            payload, 
            {
                headers: headers
            }
        );
        //console.log("Query API response:", response);
        console.log("Query API response playload:", response.data);
        return {
            chartDimensionData: response.data.dimension_series,
            chartMetricData: response.data.metric_series
        }
    } 
    catch (error) {
        console.error('Error in callQueryAPI:', error);
        return {
            dimension_series: [],
            metric_series: []
        }
    }
};

export default callQueryAPI;