// key: field name in data, value: displayed name on UI
export const displayedName = {
    // dimensions
    'source_id': 'Supply Source ID',
    'source_name': 'Supply Source Name',
    'advertiser_id': 'Advertiser ID',
    'advertiser_name': 'Advertiser Name',
    'campaign_id': 'Order ID',
    'campaign_name': 'Order Name',
    'ad_id': 'Line Item ID',
    'ad_name': 'Line Item Name',
    'response_media_type': 'Media Type',
    'is_mobile': 'Is Mobile',
    'os': 'OS',
    'country_code': 'Customer Country Code',
    'domain_name': 'Domain Name',
    'year': 'Year',
    'month': 'Month',
    'day': 'Day',
    'hour': 'Hour',
    
    // metrics
    'bids': 'Bids',
    'impressions': 'Impressions',
    'clicks': 'Click-Throughs',
    'win_rate': 'Win Rate',
    'click_through_rate': 'CTR',
    'ave_bidder_slot_bid_amount': 'Average Bid Price',
    'ave_impression_bid_amount': 'Average Impression Price',
};


export const advertiserKeyOptions = [
    { value: 'advertiser_id', label: displayedName['advertiser_id'] },
    { value: 'advertiser_name', label: displayedName['advertiser_name'] },
];


export const metricsOptions = [
    { value: 'bids', label: displayedName['bids'] },
    { value: 'impressions', label: displayedName['impressions'] },
    { value: 'clicks', label: displayedName['clicks'] },
    { value: 'win_rate', label: displayedName['win_rate'] },
    { value: 'click_through_rate', label: displayedName['click_through_rate'] },
    { value: 'ave_bidder_slot_bid_amount', label: displayedName['ave_bidder_slot_bid_amount'] },
    { value: 'ave_impression_bid_amount', label: displayedName['ave_impression_bid_amount'] },
];


export const defaultMetricsOptions = metricsOptions.slice(0, 5);


export const dimensionOptions = [
    { value: 'source_id', label: displayedName['source_id'] },
    { value: 'source_name', label: displayedName['source_name'] },
    { value: 'campaign_id', label: displayedName['campaign_id'] },
    { value: 'campaign_name', label: displayedName['campaign_name']},
    { value: 'ad_id', label: displayedName['ad_id'] },
    { value: 'ad_name', label: displayedName['ad_name'] },
    { value: 'response_media_type', label: displayedName['response_media_type'] },
    { value: 'is_mobile', label: displayedName['is_mobile'] },
    { value: 'os', label: displayedName['os'] },
    { value: 'country_code', label: displayedName['country_code'] },
    { value: 'domain_name', label: displayedName['domain_name'] },
];


export const filterKeyOptions = [
    ...dimensionOptions,
    { value: 'year', label: displayedName['year'] },
    { value: 'month', label: displayedName['month'] },
    { value: 'day', label: displayedName['day'] },
    { value: 'hour', label: displayedName['hour'] },
];

