import './App.css';
import {Amplify, Auth, Logger} from "aws-amplify";
import {Hub} from "@aws-amplify/core";
import {useEffect, useState} from "react";
import RunReport from "./utils/runReport";
import callAutocompleteAPI from "./utils/callAutocompleteAPI";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select from 'react-select';
import {displayedName, filterKeyOptions, advertiserKeyOptions, metricsOptions, defaultMetricsOptions, dimensionOptions} from "./utils/constants";


const APP_NAME = "bid-landscape";

// This is entry point of the application.
function App() {
    const logger = new Logger(APP_NAME);
    const [username, setUsername] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [isLoggedIn, setIsLoggedin] = useState(false);

    /* authentication */
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                if (
                    user.hasOwnProperty("attributes") 
                    && user.attributes.hasOwnProperty("email") 
                    && user.attributes.email.length > 0
                ) {
                    setUsername(user.attributes.email.replace('@amazon.com', ''));
                    setUserEmail(user.attributes.email);
                };
                setIsLoggedin(true);
            })
            .catch(error => {
                logger.error(error);
                Auth.federatedSignIn({customProvider: "MidwayOIDC"});
            });

        Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    logger.info("Hub listening to signIn event");
                    setIsLoggedin(true);
                    break;
                case "signIn_failure":
                    logger.error("Hub listening to signIn_failure event");
                    break;
                default:
                    break;
            }
        });
    }, []);

    useEffect(() => {
        console.log("User logged in:", isLoggedIn);
    },[isLoggedIn]);

    useEffect(() => {
        console.log("Username:", username);
    }, [username]);


    /* handle filter */
    const [selectedFilterKeyOption, setSelectedFilterKeyOption] = useState({ value: 'campaign_name', label: displayedName['campaign_name'] });
    const [filterKey, setFilterKey] = useState("campaign_name");
    const [filterValue, setFilterValue] = useState("DiscoTech | US | Core 2023 | Deals | On-Platform | Display | NA");
    const [existingFilter, setExistingFilter] = useState({[filterKey]: filterValue});
    const [newFilter, setNewFilter] = useState({});
    const [filterAutocompleteOptions, setFilterAutocompleteOptions] = useState([]);
    const [filterWaitingAutocompleteAPIResponse, setFilterWaitingAutocompleteAPIResponse] = useState(false);

    const handleAddFilter = (value) => {
        const fullFilter = Object.assign({}, existingFilter, newFilter);
        if (filterKey == "") {
            // if user does not use any filter, then set existingFilter to {}
            setExistingFilter({});
        }
        else {
            setExistingFilter(fullFilter);
        }
    }

    const handleRemoveFilters = () => {
        setSelectedFilterKeyOption(null);
        setFilterKey("");
        setFilterValue("");
        setExistingFilter({});
    }

    const handleFilterKeyChange = (option) => {
        setSelectedFilterKeyOption(option);
        setFilterKey(option.value);
        setFilterValue("");
        setFilterAutocompleteOptions([]);
    }

    const handleFilterValueChange = (value) => {
        setFilterValue(value);
    }

    useEffect(() => {
        setNewFilter({[filterKey]: filterValue});
    }, [filterKey, filterValue]);

    // only call autocomplete API after user stopped typing for 1 sec
    useEffect(() => {
        const delay = setTimeout(() => {
            if (  // conditions to call autocomplete API
                !filterWaitingAutocompleteAPIResponse
                && filterKey 
                && filterValue 
                && filterValue.length >= 1
            ) {
                setFilterWaitingAutocompleteAPIResponse(true);
                // const scope = advertiser;
                callAutocompleteAPI(filterKey, filterValue, {})
                    .then(options => {
                        //console.log(options);
                        setFilterWaitingAutocompleteAPIResponse(false);
                        setFilterAutocompleteOptions(options);
                    })
            } else {
                setFilterAutocompleteOptions([]);
            }
        }, 1000)
        return () => clearTimeout(delay);
    }, [filterValue])


    /* hanlde advertiser */
    const [selectedAdvertiserKeyOption, setSelectedAdvertiserKeyOption] = useState({ value: 'advertiser_id', label: displayedName['advertiser_id'] });
    const [advertiserKey, setAdvertiserKey] = useState("advertiser_id");
    const [advertiserValue, setAdvertiserValue] = useState("1005866");
    const [advertiser, setAdvertiser] = useState({[advertiserKey]: advertiserValue});
    const [advertiserAutocompleteOptions, setAdvertiserAutocompleteOptions] = useState([]);
    const [advertiserWaitingAutocompleteAPIResponse, setAdvertiserWaitingAutocompleteAPIResponse] = useState(false);

    const handleAdvertiserKeyChange = (option) => {
        setSelectedAdvertiserKeyOption(option);
        setAdvertiserKey(option.value);
        setAdvertiserValue("");
        setAdvertiserAutocompleteOptions([]);
    }

    const handleAdvertiserValueChange = (value) => {
        setAdvertiserValue(value);
    }

    useEffect(() => {
        setAdvertiser(Object.assign({}, {[advertiserKey]:advertiserValue}));
    }, [advertiserKey, advertiserValue]);

    // only call autocomplete API after user stopped typing for 1 sec
    useEffect(() => {
        const delay = setTimeout(() => {
            if (  // conditions to call autocomplete API
                !advertiserWaitingAutocompleteAPIResponse
                && advertiserKey 
                && advertiserValue 
                && advertiserValue.length >= 1
            ) {
                setAdvertiserWaitingAutocompleteAPIResponse(true);
                callAutocompleteAPI(advertiserKey, advertiserValue, {})
                    .then(options => {
                        //console.log(options);
                        setAdvertiserWaitingAutocompleteAPIResponse(false);
                        setAdvertiserAutocompleteOptions(options);
                    })
            } else {
                setAdvertiserAutocompleteOptions([]);
            }
        }, 1000)
        return () => clearTimeout(delay);
    }, [advertiserValue])


    /* handle metrics */ 
    const [selectedMetricsOptions, setSelectedMetricsOptions] = useState(defaultMetricsOptions);
    const handleMetricsChange = (selectedValues) => {
        setSelectedMetricsOptions(selectedValues);
    };


    /* handle dimensions */
    const [selectedDimensionOption, setSelectedDimensionOption] = useState({ value: 'ad_name', label: displayedName['ad_name'] });
    const handleDimensionChange = (selectedValue) => {
        setSelectedDimensionOption(selectedValue);        
    };


    return (
        isLoggedIn && ( 
        <div className="bids-landscape">
            <div className="app-header">
                Amazon Ads
            </div>
            <div className="app-title">
                <h1>Bid Landscape Report</h1>
            </div>
            <div className="app-intro">                
                <p> Bid Landscape Report displays ad metrics over different slices of inventories. Report data is between 2023-10-01 and 2023-10-10 Pacific Time.</p>
                <p> * Numbers are approximate as they are based on sampled data and should not be used for billing. </p>
                <p> * Check <a href="https://quip-amazon.com/6YfEAHLYrtIx/Bids-Landscape-Example-UI-User-Input"> example values</a> for "Advertiser Name/ID" and "Filters". </p>
                <p> * For quick start: simply click on "Run Report" at the bottom! </p>
            </div>
            <div className="app-body">
                <div className="select-advertiser">
                    <h3>Advertiser Name/ID:</h3>
                    <span></span><span></span>
                    <Select className="react-select-inline"
                        options={advertiserKeyOptions}
                        value={selectedAdvertiserKeyOption}
                        onChange={(option) => handleAdvertiserKeyChange(option)}
                    />
                    <span></span>=<span></span>
                    <Autocomplete
                        id="advertiser-autocomplete"
                        value={advertiserValue}
                        options={advertiserAutocompleteOptions ? advertiserAutocompleteOptions : []}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onInputChange={(event, value) => handleAdvertiserValueChange(value)}
                        sx={{ width: 500 }}
                        renderInput={(params) => <TextField
                            {...params}
                            InputProps={{ ...params.InputProps, style: {
                                fontSize: 16,
                                height: 26,
                                padding: 0
                            } }}
                            variant="outlined"
                            size="small"
                            placeholder="type to search (case sensitive)..."
                        />}
                    />
                </div>

                <div className="select-metrics">
                    <div className="hint-container">
                        <h3>Metrics:</h3>
                        <span></span><span></span>
                        <Select className="react-select-inline"
                            isMulti
                            options={metricsOptions}
                            value={selectedMetricsOptions}
                            onChange={(value) => handleMetricsChange(value)}
                        />
                        <span className="hint">(Hint)</span>
                        <div className="hint-info">                       
                        <p> Definition of metrics: </p>
                            <li key ="1">{displayedName['bids']} = total number of bids placed</li>
                            <li key ="2">{displayedName['impressions']} = total number of impressions</li>
                            <li key ="3">{displayedName['clicks']} = total number of clicks</li>
                            <li key ="4">{displayedName['win_rate']} = {displayedName['impressions']}/{displayedName['bids']} * 100</li>
                            <li key ="5">{displayedName['click_through_rate']} = {displayedName['clicks']}/{displayedName['impressions']} * 100</li>
                            <li key ="6">{displayedName['ave_bidder_slot_bid_amount']} = Sum(bid amount)/{displayedName['bids']} in US dollars</li>
                            <li key ="7">{displayedName['ave_impression_bid_amount']} = Sum(bid amount for impression)/{displayedName['impressions']} in US dollars</li>
                        </div>
                    </div>
                </div>

                <div className="select-dimension">
                    <h3>Group By:</h3>
                    <span></span><span></span>
                    <Select className="react-select-inline"
                        options={dimensionOptions}
                        value={selectedDimensionOption}
                        onChange={(value) => handleDimensionChange(value)}
                    />
                </div>

                <div className="select-filters">
                    <div className="hint-container">
                        <h3>(Optional) Filters:</h3>
                        <span></span><span></span>
                        <Select className="react-select-inline"
                            options={filterKeyOptions}
                            value={selectedFilterKeyOption}
                            onChange={(option) => handleFilterKeyChange(option)}
                        />
                        <span></span>=<span></span>
                        <Autocomplete
                            id="filter-autocomplete"
                            value={filterValue}
                            options={filterAutocompleteOptions ? filterAutocompleteOptions : []}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onInputChange={(event, value) => handleFilterValueChange(value)}
                            sx={{ width: 500 }}
                            renderInput={(params) => <TextField
                                {...params}
                                InputProps={{ ...params.InputProps, style: {
                                    fontSize: 16,
                                    height: 26,
                                    padding: 0
                                } }}
                                variant="outlined"
                                size="small"
                                placeholder="type to search (case sensitive)..."
                            />}
                        />
                        <span></span>
                        <button onClick={handleAddFilter}>add filter</button>
                        <span></span>
                        <button onClick={handleRemoveFilters}>remove filters</button>
                        <span className="hint">(Hint)</span>
                        <div className="hint-info">
                            <li key="1">Filters are optional but recommended.</li>
                            <li key="2">You can apply multiple filters, e.g. "{displayedName['source_name']} = RUBICON", "{displayedName['campaign_name']} = DiscoTech | US | Core 2023 | Deals | On-Platform | Display | NA".</li>
                            <li key="3">Click "remove filters" to remove all filters.</li>
                        </div>
                    </div>

            
                    <div className="show-filters">
                        <p> Applied filters: </p>
                        {Object.entries(existingFilter).map(([key, value]) => <li key={key}>{displayedName[key]} = {value}</li>)}                  
                    </div>
                </div>

                <div className="run-report">
                    <RunReport 
                        advertiserDict={advertiser} 
                        metricList={selectedMetricsOptions.map(option => option.value)} 
                        dimension={selectedDimensionOption.value} 
                        filterDict={existingFilter}
                    />
                </div>
            </div>
        </div>
        )        
    );
 }

export default App;